<script>
export default {
  components: {},
};
</script>

<template>
  <div class="footer-container footer-container-public">
    <div class="footer-links-container">
      <div class="footer-logo-container">
        <div class="footer-logo-redpointstudio">
          <a href="#top">
            <svg
              class="footer-logo"
              width="147"
              height="33"
              viewBox="0 0 147 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_201_752)">
                <path
                  d="M44.1534 28.889H45.1982C45.1982 29.8625 45.894 30.3647 46.951 30.3647C47.8497 30.3647 48.4908 29.9079 48.4908 29.1742C48.4908 28.4404 47.726 28.0746 46.8618 27.7439C45.6932 27.3098 44.3563 26.8737 44.3563 25.2719C44.3563 23.8747 45.535 23.0975 46.7826 23.0975C48.1196 23.0975 49.3104 23.9222 49.3104 25.4889H48.2656C48.2656 24.596 47.659 24.1268 46.7826 24.1268C45.9955 24.1268 45.3889 24.5505 45.3889 25.2719C45.3889 26.0842 46.0969 26.4045 46.9612 26.7373C48.1399 27.1941 49.5336 27.6302 49.5336 29.1742C49.5336 30.5817 48.3204 31.4064 46.949 31.4064C45.4315 31.4064 44.1514 30.5363 44.1514 28.889H44.1534Z"
                  fill="white"
                />
                <path
                  d="M50.3555 28.397C50.3555 26.7022 51.6701 25.3876 53.3093 25.3876C54.9485 25.3876 56.2753 26.7042 56.2753 28.397C56.2753 30.0898 54.9505 31.4064 53.3093 31.4064C51.6681 31.4064 50.3555 30.0898 50.3555 28.397ZM55.2975 28.397C55.2975 27.2416 54.4332 26.3591 53.3093 26.3591C52.1854 26.3591 51.3313 27.2396 51.3313 28.397C51.3313 29.5545 52.1955 30.435 53.3093 30.435C54.4231 30.435 55.2975 29.5545 55.2975 28.397Z"
                  fill="white"
                />
                <path
                  d="M61.8235 25.5365V31.2597H60.8457V30.5507C60.442 31.1811 59.8678 31.4085 59.1821 31.4085C58.1373 31.4085 57.2954 30.5962 57.2954 29.2445V25.5365H58.2733V29.2114C58.2733 30.0361 58.7683 30.4598 59.4418 30.4598C60.1153 30.4598 60.8457 30.0257 60.8457 28.7897V25.5385H61.8235V25.5365Z"
                  fill="white"
                />
                <path
                  d="M67.8348 27.5393V31.2597H66.8569V27.5847C66.8569 26.7601 66.3619 26.3363 65.6884 26.3363C65.0148 26.3363 64.2845 26.7704 64.2845 27.9961V31.2576H63.3066V25.5344H64.2845V26.2433C64.6882 25.6129 65.2725 25.3856 65.9481 25.3856C66.9929 25.3856 67.8348 26.1979 67.8348 27.5372V27.5393Z"
                  fill="white"
                />
                <path
                  d="M74.8114 23.2484V31.2597H73.8335V30.2862C73.3831 30.9724 72.6548 31.4085 71.7094 31.4085C70.1595 31.4085 68.8672 30.0919 68.8672 28.3991C68.8672 26.7063 70.1595 25.3897 71.7094 25.3897C72.6528 25.3897 73.3831 25.8238 73.8335 26.512V23.2505H74.8114V23.2484ZM73.8335 28.3991C73.8335 27.2313 72.9693 26.3508 71.8332 26.3508C70.6971 26.3508 69.845 27.2313 69.845 28.3991C69.845 29.5669 70.7093 30.4474 71.8332 30.4474C72.9571 30.4474 73.8335 29.5669 73.8335 28.3991Z"
                  fill="white"
                />
                <path
                  d="M83.1254 29.7033C83.1254 30.7326 82.2266 31.4085 80.979 31.4085C79.7313 31.4085 78.8447 30.7223 78.8447 29.5421H79.8104C79.8104 30.2283 80.3379 30.4805 80.979 30.4805C81.62 30.4805 82.1475 30.2407 82.1475 29.7012C82.1475 28.4074 78.934 29.2445 78.934 27.0928C78.934 26.0635 79.8327 25.3876 80.979 25.3876C82.1252 25.3876 83.0117 26.0738 83.0117 27.254H82.0461C82.0461 26.5678 81.5186 26.3157 80.979 26.3157C80.4393 26.3157 79.9118 26.5554 79.9118 27.0949C79.9118 28.3888 83.1254 27.5413 83.1254 29.7033Z"
                  fill="white"
                />
                <path
                  d="M86.8885 26.4976H85.7869V31.2597H84.809V26.4976H83.6973V25.5365H84.809V23.7052H85.7869V25.5365H86.8885V26.4976Z"
                  fill="white"
                />
                <path
                  d="M92.2244 25.5365V31.2597H91.2466V30.5507C90.8429 31.1811 90.2687 31.4085 89.583 31.4085C88.5382 31.4085 87.6963 30.5962 87.6963 29.2445V25.5365H88.6741V29.2114C88.6741 30.0361 89.1692 30.4598 89.8427 30.4598C90.5162 30.4598 91.2466 30.0257 91.2466 28.7897V25.5385H92.2244V25.5365Z"
                  fill="white"
                />
                <path
                  d="M99.3347 23.2484V31.2597H98.3569V30.2862C97.9085 30.9724 97.1782 31.4085 96.2348 31.4085C94.6849 31.4085 93.3926 30.0919 93.3926 28.3991C93.3926 26.7063 94.6849 25.3897 96.2348 25.3897C97.1782 25.3897 97.9085 25.8238 98.3569 26.512V23.2505H99.3347V23.2484ZM98.3569 28.3991C98.3569 27.2313 97.4926 26.3508 96.3566 26.3508C95.2205 26.3508 94.3684 27.2313 94.3684 28.3991C94.3684 29.5669 95.2326 30.4474 96.3566 30.4474C97.4805 30.4474 98.3569 29.5669 98.3569 28.3991Z"
                  fill="white"
                />
                <path
                  d="M101.307 25.5365C101.577 25.5365 101.796 25.7597 101.796 26.0346V31.2597H100.818V26.0346C100.818 25.7597 101.037 25.5365 101.307 25.5365Z"
                  fill="white"
                />
                <path
                  d="M102.977 28.397C102.977 26.7022 104.291 25.3876 105.93 25.3876C107.57 25.3876 108.896 26.7042 108.896 28.397C108.896 30.0898 107.572 31.4064 105.93 31.4064C104.289 31.4064 102.977 30.0898 102.977 28.397ZM107.919 28.397C107.919 27.2416 107.054 26.3591 105.93 26.3591C104.806 26.3591 103.952 27.2396 103.952 28.397C103.952 29.5545 104.817 30.435 105.93 30.435C107.044 30.435 107.919 29.5545 107.919 28.397Z"
                  fill="white"
                />
                <path
                  d="M0.000126152 16.5455C0.0366434 7.47802 7.14533 0.161219 15.8973 0.186022C24.706 0.210824 31.7701 7.59376 31.7762 16.6674C31.7823 25.3711 24.7101 33.1798 15.4043 32.9979C7.00737 32.8346 -0.0343623 25.4124 0.000126152 16.5455Z"
                  fill="#8A0917"
                />
                <path
                  d="M56.6081 5.8183C56.6081 2.59395 54.062 0 50.8972 0H44.2349V16.9692H48.0428V3.63566H50.8972C51.9441 3.63566 52.8002 4.58023 52.8002 5.8183C52.8002 7.05637 51.9441 8.00094 50.8972 8.00094H48.7691V11.3948H49.8504L52.9909 16.9713H57.0828L53.5366 10.7416C55.3686 9.795 56.6061 7.90586 56.6061 5.82037L56.6081 5.8183Z"
                  fill="white"
                />
                <path
                  d="M63.8892 4.50789C60.13 4.50789 57.5596 7.29613 57.5596 10.907C57.5596 14.5178 60.0813 17.3061 64.1509 17.3061C66.4109 17.3061 68.1719 16.4587 69.3384 14.8093L66.484 13.1372C66.0072 13.6704 65.1754 14.059 64.1996 14.059C62.8911 14.059 61.7956 13.6229 61.3452 12.3621H69.7928C69.8882 11.9012 69.9348 11.4175 69.9348 10.907C69.9348 7.24652 67.3644 4.50789 63.8912 4.50789H63.8892ZM61.2722 9.64618C61.6049 8.41018 62.5097 7.73017 63.8649 7.73017C64.936 7.73017 66.0072 8.23863 66.3866 9.64618H61.2701H61.2722Z"
                  fill="white"
                />
                <path
                  d="M80.472 5.98779C79.6869 5.06595 78.5204 4.50996 76.9258 4.50996C73.8076 4.50996 71.2393 7.2982 71.2393 10.9091C71.2393 14.5199 73.8097 17.3082 76.9258 17.3082C78.5204 17.3082 79.6849 16.7501 80.472 15.8303V16.9692H84.0406V0H80.472V5.98779ZM77.6399 13.8668C75.9987 13.8668 74.8078 12.7279 74.8078 10.9091C74.8078 9.09019 75.9966 7.95133 77.6399 7.95133C79.2832 7.95133 80.472 9.09019 80.472 10.9091C80.472 12.7279 79.2832 13.8668 77.6399 13.8668Z"
                  fill="white"
                />
                <path
                  d="M99.0309 0H92.7256V16.9692H96.5335V3.63566H99.0329C100.199 3.63566 101.056 4.58023 101.056 5.8183C101.056 7.05637 100.199 8.00094 99.0329 8.00094H97.2618V11.6366H99.0329C102.317 11.6366 104.861 9.04265 104.861 5.8183C104.861 2.59395 102.315 0 99.0309 0Z"
                  fill="white"
                />
                <path
                  d="M111.644 4.50789C108.146 4.50789 105.338 7.29613 105.338 10.907C105.338 14.5178 108.146 17.3061 111.644 17.3061C115.141 17.3061 117.949 14.5178 117.949 10.907C117.949 7.29613 115.141 4.50789 111.644 4.50789ZM111.644 13.7676C110.098 13.7676 108.907 12.6287 108.907 10.907C108.907 9.18527 110.096 8.04641 111.644 8.04641C113.192 8.04641 114.38 9.18527 114.38 10.907C114.38 12.6287 113.192 13.7676 111.644 13.7676Z"
                  fill="white"
                />
                <path
                  d="M121.755 4.67738C120.769 4.67738 119.97 5.50207 119.97 6.52104V16.9692H123.538V6.52104C123.538 5.50207 122.739 4.67738 121.753 4.67738H121.755Z"
                  fill="white"
                />
                <path
                  d="M133.128 4.50789C131.558 4.50789 130.367 5.08868 129.726 5.98572V4.84686H126.157V16.9671H129.726V10.3489C129.726 8.6272 130.631 7.85212 131.939 7.85212C133.081 7.85212 134.008 8.55486 134.008 10.0575V16.9671H137.577V9.52424C137.577 6.25235 135.53 4.50582 133.128 4.50582V4.50789Z"
                  fill="white"
                />
                <path
                  d="M147 8.33991V4.84893H144.55V1.45509L140.981 2.54641V4.84893H139.078V8.33991H140.981V12.6308C140.981 16.0246 142.338 17.4549 147 16.9692V13.6725C145.43 13.7696 144.55 13.6725 144.55 12.6308V8.33991H147Z"
                  fill="white"
                />
                <path d="M38.414 0H37.3145V33H38.414V0Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_201_752">
                  <rect width="147" height="33" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div class="footer-logo-audability">
          <router-link to="/homePagePrivate">
            <svg
              width="161"
              height="40"
              viewBox="0 0 161 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_232_5506)">
                <path
                  d="M42.999 1.52045V39.2198C42.999 39.4911 42.7062 39.6719 42.2669 39.6719C41.8276 39.6719 41.5348 39.4911 41.5348 39.2198V1.56565C41.5348 1.29443 41.8276 1.11362 42.2669 1.11362C42.7062 1.11362 42.999 1.29443 42.999 1.52045Z"
                  fill="white"
                />
                <path
                  d="M18.6702 37.1271C28.4642 37.1271 36.4038 29.2799 36.4038 19.5999C36.4038 9.91993 28.4642 2.07275 18.6702 2.07275C8.87615 2.07275 0.936523 9.91993 0.936523 19.5999C0.936523 29.2799 8.87615 37.1271 18.6702 37.1271Z"
                  fill="#8A0917"
                />
                <path
                  d="M50.917 16.8142C50.917 21.0027 54.3981 24.3257 58.6359 24.3257C60.7873 24.3257 62.7548 23.4602 64.0521 21.9323C64.117 21.8415 64.3278 21.7827 64.3278 22.1406C64.3278 22.349 64.3278 23.0382 64.3278 23.0382C64.3278 23.5457 64.7494 23.9678 65.2629 23.9678H65.3278C65.8413 23.9678 66.2629 23.5457 66.2629 23.0382V10.5315C66.2629 10.0239 65.8413 9.60721 65.3278 9.60721H65.2629C64.7494 9.60721 64.3278 10.0239 64.3278 10.5315C64.3278 10.8947 64.3278 11.3702 64.3278 11.4023C64.3278 11.7923 64.0251 11.7015 63.9332 11.5519C62.7548 10.0827 60.6629 9.24927 58.5765 9.24927C54.3386 9.24927 50.917 12.6257 50.917 16.8142ZM52.8521 16.8142C52.8521 13.7049 55.3981 11.0443 58.5765 11.0443C61.7224 11.0443 64.2954 13.7049 64.2954 16.8142C64.2954 19.9289 61.7819 22.5306 58.6359 22.5306C55.4575 22.5306 52.8521 19.9289 52.8521 16.8142Z"
                  fill="white"
                />
                <path
                  d="M80.4306 9.60718H80.3711C79.8576 9.60718 79.4306 10.0239 79.4306 10.5314V18.2246C79.4306 21.1843 77.463 22.5306 75.3441 22.5306C73.2576 22.5306 71.2576 21.1843 71.2576 18.2246V10.5314C71.2576 10.0239 70.836 9.60718 70.3225 9.60718H70.2306C69.7171 9.60718 69.29 10.0239 69.29 10.5314V18.3421C69.29 22.9794 72.5927 24.3257 75.3441 24.3257C78.1008 24.3257 81.3711 22.9794 81.3711 18.3421V10.5314C81.3711 10.0239 80.9441 9.60718 80.4306 9.60718Z"
                  fill="white"
                />
                <path
                  d="M98.9877 2.99324C98.9877 2.51776 98.5606 2.0957 98.0796 2.0957H97.9552C97.4417 2.0957 97.0201 2.51776 97.0201 2.99324C97.0201 2.99324 97.0201 10.4139 97.0201 11.5786C97.0201 11.819 96.7769 11.819 96.685 11.6694C95.2309 9.9331 93.5066 9.24927 91.4796 9.24927C89.3931 9.24927 87.5769 9.9331 86.0904 11.429C84.5769 12.8982 83.8525 14.6612 83.8525 16.7554C83.8525 18.8497 84.5769 20.618 86.0904 22.1139C87.5769 23.5778 89.3931 24.3257 91.4796 24.3257C93.5066 24.3257 95.4147 23.3694 96.8093 21.7827C96.9606 21.601 97.0201 21.8147 97.0201 21.9002C97.0201 22.1406 97.0201 23.0382 97.0201 23.0382C97.0201 23.5457 97.4417 23.9357 97.9552 23.9357H98.0796C98.5606 23.9357 98.9877 23.5457 98.9877 23.0382V2.99324ZM97.1715 16.7554C97.1715 19.8701 94.6255 22.5306 91.4796 22.5306C88.3336 22.5306 85.7877 19.8701 85.7877 16.7554C85.7877 13.6461 88.3336 11.0443 91.4796 11.0443C94.6255 11.0443 97.1715 13.6461 97.1715 16.7554Z"
                  fill="white"
                />
                <path
                  d="M103.015 9.60718H102.95C102.436 9.60718 102.015 10.0239 102.015 10.5314V23.0114C102.015 23.519 102.436 23.9357 102.95 23.9357H103.015C103.528 23.9357 103.95 23.519 103.95 23.0114V10.5314C103.95 10.0239 103.528 9.60718 103.015 9.60718Z"
                  fill="white"
                />
                <path
                  d="M106.977 2.99324V23.0382C106.977 23.5457 107.404 23.9357 107.885 23.9357H108.009C108.523 23.9357 108.944 23.5457 108.944 23.0382C108.944 23.0382 108.944 22.1406 108.944 21.9002C108.944 21.8147 109.004 21.601 109.155 21.7827C110.55 23.3694 112.458 24.3257 114.485 24.3257C116.571 24.3257 118.387 23.5778 119.874 22.1139C121.387 20.618 122.112 18.8497 122.112 16.7554C122.112 14.6612 121.387 12.8982 119.874 11.429C118.387 9.9331 116.571 9.24927 114.485 9.24927C112.458 9.24927 110.733 9.9331 109.279 11.6694C109.187 11.819 108.944 11.819 108.944 11.5786C108.944 10.4139 108.944 2.99324 108.944 2.99324C108.944 2.51776 108.523 2.0957 108.009 2.0957H107.885C107.404 2.0957 106.977 2.51776 106.977 2.99324ZM108.793 16.7554C108.793 13.6461 111.339 11.0443 114.485 11.0443C117.631 11.0443 120.177 13.6461 120.177 16.7554C120.177 19.8701 117.631 22.5306 114.485 22.5306C111.339 22.5306 108.793 19.8701 108.793 16.7554Z"
                  fill="white"
                />
                <path
                  d="M125.593 9.60718H125.533C125.02 9.60718 124.593 10.0239 124.593 10.5314V23.0114C124.593 23.519 125.02 23.9357 125.533 23.9357H125.593C126.106 23.9357 126.533 23.519 126.533 23.0114V10.5314C126.533 10.0239 126.106 9.60718 125.593 9.60718Z"
                  fill="white"
                />
                <path
                  d="M130.561 2.0957H130.496C129.982 2.0957 129.561 2.51776 129.561 3.02529V23.0115C129.561 23.519 129.982 23.9357 130.496 23.9357H130.561C131.074 23.9357 131.496 23.519 131.496 23.0115V3.02529C131.496 2.51776 131.074 2.0957 130.561 2.0957Z"
                  fill="white"
                />
                <path
                  d="M135.522 9.60718H135.463C134.949 9.60718 134.522 10.0239 134.522 10.5314V23.0114C134.522 23.519 134.949 23.9357 135.463 23.9357H135.522C136.036 23.9357 136.463 23.519 136.463 23.0114V10.5314C136.463 10.0239 136.036 9.60718 135.522 9.60718Z"
                  fill="white"
                />
                <path
                  d="M145.393 9.60721C145.393 9.60721 144.209 9.60721 143.966 9.60721C143.728 9.60721 143.604 9.45762 143.604 9.21721C143.604 8.95009 143.604 3.02529 143.604 3.02529C143.604 2.51776 143.182 2.0957 142.669 2.0957H142.604C142.09 2.0957 141.669 2.51776 141.669 3.02529C141.669 3.02529 141.669 8.8005 141.669 9.12639C141.669 9.42557 141.544 9.60721 141.307 9.60721C141.063 9.60721 139.879 9.60721 139.879 9.60721C139.366 9.60721 138.944 10.0239 138.944 10.5315V10.5956C138.944 11.1031 139.366 11.5198 139.879 11.5198C139.879 11.5198 141.123 11.5198 141.334 11.5198C141.577 11.5198 141.669 11.6427 141.669 11.8778C141.669 12.1502 141.669 23.0115 141.669 23.0115C141.669 23.519 142.09 23.9357 142.604 23.9357H142.669C143.182 23.9357 143.604 23.519 143.604 23.0115C143.604 23.0115 143.604 12.1182 143.604 11.851C143.604 11.5786 143.696 11.5198 143.998 11.5198C144.269 11.5198 145.393 11.5198 145.393 11.5198C145.907 11.5198 146.328 11.1031 146.328 10.5956V10.5315C146.328 10.0239 145.907 9.60721 145.393 9.60721Z"
                  fill="white"
                />
                <path
                  d="M152.355 26.3611L160.041 10.2322C160.193 9.8743 160.009 9.60718 159.62 9.60718H158.831C158.436 9.60718 157.982 9.8743 157.803 10.2322L154.139 18.7909C153.803 19.5068 153.895 19.5388 153.533 18.7909L149.901 10.2322C149.717 9.8743 149.263 9.60718 148.874 9.60718H148.085C147.69 9.60718 147.512 9.8743 147.663 10.2322L152.441 20.3188C152.717 20.9172 152.717 21.0027 152.441 21.601L150.144 26.3611C149.96 26.7191 150.144 27.0183 150.539 27.0183H151.322C151.749 27.0183 152.203 26.7191 152.355 26.3611Z"
                  fill="white"
                />
                <path
                  d="M54.8978 35.5987C54.8761 35.4705 54.8978 35.3654 54.9626 35.2835C55.0275 35.2016 55.114 35.1606 55.2221 35.1606C55.5176 35.1606 55.6905 35.2639 55.741 35.4705C55.8347 35.8195 55.9933 36.0759 56.2167 36.2398C56.4401 36.4036 56.7752 36.4855 57.2221 36.4855C57.5176 36.4855 57.7806 36.3965 58.0113 36.2184C58.2419 36.0403 58.3572 35.8124 58.3572 35.5346C58.3572 35.3636 58.3158 35.2194 58.2329 35.1018C58.15 34.9843 58.0257 34.8899 57.8599 34.8187C57.6942 34.7474 57.5554 34.6958 57.4437 34.6637C57.332 34.6317 57.1752 34.5979 56.9734 34.5622C56.7212 34.5124 56.5266 34.4714 56.3897 34.4394C56.2527 34.4073 56.0761 34.3503 55.8599 34.2684C55.6437 34.1865 55.4797 34.0939 55.368 33.9906C55.2563 33.8873 55.1554 33.7484 55.0653 33.5739C54.9752 33.3994 54.9302 33.1946 54.9302 32.9595C54.9302 32.4324 55.1302 32.0281 55.5302 31.7468C55.9302 31.4654 56.4005 31.3247 56.941 31.3247C58.0725 31.3247 58.7464 31.7379 58.9626 32.5642C59.0419 32.8491 58.9086 32.9916 58.5626 32.9916C58.3104 32.9916 58.1662 32.9061 58.1302 32.7351C58.0437 32.2365 57.6581 31.9872 56.9734 31.9872C56.6779 31.9872 56.4041 32.0602 56.1518 32.2062C55.8996 32.3522 55.7734 32.5677 55.7734 32.8527C55.7734 32.9524 55.7933 33.045 55.8329 33.1305C55.8725 33.2159 55.9338 33.2872 56.0167 33.3442C56.0996 33.4011 56.1806 33.451 56.2599 33.4937C56.3392 33.5365 56.4419 33.5721 56.568 33.6006C56.6942 33.6291 56.7914 33.6505 56.8599 33.6647C56.9284 33.679 57.0239 33.6968 57.1464 33.7181C57.4491 33.768 57.7032 33.8232 57.9086 33.8837C58.114 33.9443 58.3302 34.0369 58.5572 34.1616C58.7842 34.2862 58.9554 34.4572 59.0707 34.6744C59.186 34.8917 59.2437 35.1535 59.2437 35.4598C59.2437 36.0296 59.0311 36.4553 58.6059 36.7366C58.1806 37.018 57.6797 37.1587 57.1032 37.1587C56.4689 37.1587 55.9626 37.0216 55.5842 36.7473C55.2059 36.4731 54.977 36.0902 54.8978 35.5987Z"
                  fill="white"
                />
                <path
                  d="M59.8926 34.2524C59.8926 32.3291 60.6926 31.3674 62.2926 31.3674C63.8854 31.3674 64.6818 32.3291 64.6818 34.2524C64.6818 36.1614 63.8854 37.1159 62.2926 37.1159C60.6926 37.1159 59.8926 36.1614 59.8926 34.2524ZM62.2926 36.5283C63.2944 36.5283 63.7953 35.7696 63.7953 34.2524C63.7953 32.7351 63.2944 31.9765 62.2926 31.9765C61.2908 31.9765 60.7899 32.7351 60.7899 34.2524C60.7899 35.7696 61.2908 36.5283 62.2926 36.5283Z"
                  fill="white"
                />
                <path
                  d="M65.6543 31.7414C65.6543 31.4921 65.7948 31.3674 66.0759 31.3674C66.357 31.3674 66.4975 31.4921 66.4975 31.7414V34.9896C66.4975 35.4384 66.5624 35.7874 66.6921 36.0367C66.8219 36.2861 67.0885 36.4107 67.4921 36.4107C67.9678 36.4107 68.3516 36.2113 68.6435 35.8124C68.9354 35.4135 69.0813 34.9647 69.0813 34.4661V31.7414C69.0813 31.4921 69.2255 31.3674 69.5138 31.3674C69.7948 31.3674 69.9354 31.4921 69.9354 31.7414V36.6885C69.9354 36.9378 69.7948 37.0625 69.5138 37.0625C69.2687 37.0625 69.1462 36.9378 69.1462 36.6885V35.9513C68.721 36.7277 68.1228 37.1159 67.3516 37.1159C66.2201 37.1159 65.6543 36.4285 65.6543 35.0537V31.7414Z"
                  fill="white"
                />
                <path
                  d="M74.7787 36.7526V33.3228C74.7787 32.9951 74.7048 32.7102 74.557 32.468C74.4093 32.2258 74.1516 32.1047 73.7841 32.1047C73.3012 32.1047 72.9138 32.2988 72.6219 32.687C72.33 33.0752 72.1841 33.5187 72.1841 34.0173V36.7526C72.1841 36.9948 72.0435 37.1159 71.7624 37.1159C71.4814 37.1159 71.3408 36.9948 71.3408 36.7526V31.7307C71.3408 31.4885 71.4742 31.3674 71.7408 31.3674C72.0003 31.3674 72.13 31.4885 72.13 31.7307V32.5428C72.5408 31.7592 73.1498 31.3674 73.957 31.3674C74.2237 31.3674 74.4615 31.4137 74.6705 31.5063C74.8796 31.5989 75.0417 31.7129 75.157 31.8483C75.2724 31.9836 75.3678 32.1421 75.4435 32.3237C75.5192 32.5054 75.5678 32.671 75.5895 32.8206C75.6111 32.9702 75.6219 33.1198 75.6219 33.2693V36.7526C75.6219 36.9948 75.4814 37.1159 75.2003 37.1159C74.9192 37.1159 74.7787 36.9948 74.7787 36.7526Z"
                  fill="white"
                />
                <path
                  d="M80.3677 32.2223V29.8503C80.3677 29.7363 80.4055 29.6455 80.4812 29.5778C80.5569 29.5102 80.6596 29.4763 80.7893 29.4763C81.0704 29.4763 81.2109 29.601 81.2109 29.8503V36.7421C81.2109 36.9914 81.0776 37.116 80.8109 37.116C80.5515 37.116 80.4217 36.9914 80.4217 36.7421V36.1437C80.292 36.4215 80.0776 36.6584 79.7785 36.8543C79.4794 37.0502 79.1569 37.1481 78.8109 37.1481C78.047 37.1481 77.4866 36.8774 77.1299 36.336C76.7731 35.7947 76.5947 35.0966 76.5947 34.2418C76.5947 32.3043 77.2974 31.3355 78.7028 31.3355C79.3803 31.3355 79.9353 31.6311 80.3677 32.2223ZM80.3677 34.3593V33.9212C80.3677 33.4012 80.2398 32.9632 79.9839 32.607C79.7281 32.2508 79.3515 32.0728 78.8542 32.0728C77.9677 32.0728 77.5245 32.7958 77.5245 34.2418C77.5245 35.6878 77.9677 36.4108 78.8542 36.4108C79.3803 36.4108 79.7641 36.2096 80.0055 35.8071C80.247 35.4047 80.3677 34.9221 80.3677 34.3593Z"
                  fill="white"
                />
                <path
                  d="M85.6546 35.5987C85.633 35.4705 85.6546 35.3654 85.7195 35.2835C85.7843 35.2016 85.8708 35.1606 85.9789 35.1606C86.2744 35.1606 86.4474 35.2639 86.4978 35.4705C86.5915 35.8195 86.7501 36.0759 86.9735 36.2398C87.1969 36.4036 87.5321 36.4855 87.9789 36.4855C88.2744 36.4855 88.5375 36.3965 88.7681 36.2184C88.9987 36.0403 89.1141 35.8124 89.1141 35.5346C89.1141 35.3636 89.0726 35.2194 88.9897 35.1018C88.9068 34.9843 88.7825 34.8899 88.6168 34.8187C88.451 34.7474 88.3123 34.6958 88.2005 34.6637C88.0888 34.6317 87.9321 34.5979 87.7303 34.5622C87.478 34.5124 87.2834 34.4714 87.1465 34.4394C87.0095 34.4073 86.833 34.3503 86.6168 34.2684C86.4005 34.1865 86.2366 34.0939 86.1249 33.9906C86.0132 33.8873 85.9123 33.7484 85.8222 33.5739C85.7321 33.3994 85.687 33.1946 85.687 32.9595C85.687 32.4324 85.887 32.0281 86.287 31.7468C86.687 31.4654 87.1573 31.3247 87.6978 31.3247C88.8294 31.3247 89.5032 31.7379 89.7195 32.5642C89.7987 32.8491 89.6654 32.9916 89.3195 32.9916C89.0672 32.9916 88.9231 32.9061 88.887 32.7351C88.8005 32.2365 88.415 31.9872 87.7303 31.9872C87.4348 31.9872 87.1609 32.0602 86.9086 32.2062C86.6564 32.3522 86.5303 32.5677 86.5303 32.8527C86.5303 32.9524 86.5501 33.045 86.5897 33.1305C86.6294 33.2159 86.6906 33.2872 86.7735 33.3442C86.8564 33.4011 86.9375 33.451 87.0168 33.4937C87.096 33.5365 87.1987 33.5721 87.3249 33.6006C87.451 33.6291 87.5483 33.6505 87.6168 33.6647C87.6852 33.679 87.7807 33.6968 87.9032 33.7181C88.2059 33.768 88.46 33.8232 88.6654 33.8837C88.8708 33.9443 89.087 34.0369 89.3141 34.1616C89.5411 34.2862 89.7123 34.4572 89.8276 34.6744C89.9429 34.8917 90.0005 35.1535 90.0005 35.4598C90.0005 36.0296 89.7879 36.4553 89.3627 36.7366C88.9375 37.018 88.4366 37.1587 87.86 37.1587C87.2258 37.1587 86.7195 37.0216 86.3411 36.7473C85.9627 36.4731 85.7339 36.0902 85.6546 35.5987Z"
                  fill="white"
                />
                <path
                  d="M90.6279 31.4744H91.3522V30.406C91.3522 30.1566 91.4928 30.032 91.7738 30.032C92.0549 30.032 92.1955 30.1566 92.1955 30.406V31.4744H93.2225C93.4243 31.4744 93.5252 31.5884 93.5252 31.8164C93.5252 32.0372 93.4243 32.1476 93.2225 32.1476H92.1955V35.289C92.1955 35.9942 92.4657 36.3468 93.0063 36.3468C93.1 36.3468 93.2333 36.3325 93.4063 36.304C93.6513 36.2399 93.7774 36.3183 93.7847 36.5391C93.7847 36.767 93.7018 36.9131 93.536 36.9772C93.3774 37.0342 93.2117 37.0627 93.0387 37.0627C92.4045 37.0627 91.9648 36.9077 91.7198 36.5979C91.4747 36.288 91.3522 35.8517 91.3522 35.289V32.1476H90.6279C90.4261 32.1476 90.3252 32.0372 90.3252 31.8164C90.3252 31.5884 90.4261 31.4744 90.6279 31.4744Z"
                  fill="white"
                />
                <path
                  d="M94.541 31.7414C94.541 31.4921 94.6816 31.3674 94.9626 31.3674C95.2437 31.3674 95.3843 31.4921 95.3843 31.7414V34.9896C95.3843 35.4384 95.4491 35.7874 95.5789 36.0367C95.7086 36.2861 95.9753 36.4107 96.3789 36.4107C96.8545 36.4107 97.2383 36.2113 97.5302 35.8124C97.8221 35.4135 97.968 34.9647 97.968 34.4661V31.7414C97.968 31.4921 98.1122 31.3674 98.4005 31.3674C98.6816 31.3674 98.8221 31.4921 98.8221 31.7414V36.6885C98.8221 36.9378 98.6816 37.0625 98.4005 37.0625C98.1554 37.0625 98.0329 36.9378 98.0329 36.6885V35.9513C97.6077 36.7277 97.0095 37.1159 96.2383 37.1159C95.1068 37.1159 94.541 36.4285 94.541 35.0537V31.7414Z"
                  fill="white"
                />
                <path
                  d="M103.676 32.2223V29.8503C103.676 29.7363 103.714 29.6455 103.79 29.5778C103.865 29.5102 103.968 29.4763 104.098 29.4763C104.379 29.4763 104.52 29.601 104.52 29.8503V36.7421C104.52 36.9914 104.386 37.116 104.12 37.116C103.86 37.116 103.73 36.9914 103.73 36.7421V36.1437C103.601 36.4215 103.386 36.6584 103.087 36.8543C102.788 37.0502 102.465 37.1481 102.12 37.1481C101.356 37.1481 100.795 36.8774 100.438 36.336C100.082 35.7947 99.9033 35.0966 99.9033 34.2418C99.9033 32.3043 100.606 31.3355 102.011 31.3355C102.689 31.3355 103.244 31.6311 103.676 32.2223ZM103.676 34.3593V33.9212C103.676 33.4012 103.548 32.9632 103.293 32.607C103.037 32.2508 102.66 32.0728 102.163 32.0728C101.276 32.0728 100.833 32.7958 100.833 34.2418C100.833 35.6878 101.276 36.4108 102.163 36.4108C102.689 36.4108 103.073 36.2096 103.314 35.8071C103.556 35.4047 103.676 34.9221 103.676 34.3593Z"
                  fill="white"
                />
                <path
                  d="M106.855 31.7415V36.7528C106.855 36.9949 106.714 37.116 106.433 37.116C106.152 37.116 106.011 36.9949 106.011 36.7528V31.7415C106.011 31.4922 106.152 31.3676 106.433 31.3676C106.714 31.3676 106.855 31.4922 106.855 31.7415ZM105.925 29.9999C105.925 29.6508 106.094 29.4763 106.433 29.4763C106.772 29.4763 106.941 29.6508 106.941 29.9999C106.941 30.3774 106.772 30.5662 106.433 30.5662C106.231 30.5662 106.096 30.5181 106.028 30.4219C105.959 30.3258 105.925 30.1851 105.925 29.9999Z"
                  fill="white"
                />
                <path
                  d="M108.022 34.2524C108.022 32.3291 108.822 31.3674 110.422 31.3674C112.015 31.3674 112.812 32.3291 112.812 34.2524C112.812 36.1614 112.015 37.1159 110.422 37.1159C108.822 37.1159 108.022 36.1614 108.022 34.2524ZM110.422 36.5283C111.424 36.5283 111.925 35.7696 111.925 34.2524C111.925 32.7351 111.424 31.9765 110.422 31.9765C109.421 31.9765 108.92 32.7351 108.92 34.2524C108.92 35.7696 109.421 36.5283 110.422 36.5283Z"
                  fill="white"
                />
                <path
                  d="M116.93 35.5987C116.908 35.4705 116.93 35.3654 116.995 35.2835C117.06 35.2016 117.146 35.1606 117.254 35.1606C117.55 35.1606 117.723 35.2639 117.773 35.4705C117.867 35.8195 118.025 36.0759 118.249 36.2398C118.472 36.4036 118.807 36.4855 119.254 36.4855C119.55 36.4855 119.813 36.3965 120.043 36.2184C120.274 36.0403 120.389 35.8124 120.389 35.5346C120.389 35.3636 120.348 35.2194 120.265 35.1018C120.182 34.9843 120.058 34.8899 119.892 34.8187C119.726 34.7474 119.588 34.6958 119.476 34.6637C119.364 34.6317 119.207 34.5979 119.006 34.5622C118.753 34.5124 118.559 34.4714 118.422 34.4394C118.285 34.4073 118.108 34.3503 117.892 34.2684C117.676 34.1865 117.512 34.0939 117.4 33.9906C117.289 33.8873 117.188 33.7484 117.098 33.5739C117.007 33.3994 116.962 33.1946 116.962 32.9595C116.962 32.4324 117.162 32.0281 117.562 31.7468C117.962 31.4654 118.433 31.3247 118.973 31.3247C120.105 31.3247 120.779 31.7379 120.995 32.5642C121.074 32.8491 120.941 32.9916 120.595 32.9916C120.343 32.9916 120.198 32.9061 120.162 32.7351C120.076 32.2365 119.69 31.9872 119.006 31.9872C118.71 31.9872 118.436 32.0602 118.184 32.2062C117.932 32.3522 117.806 32.5677 117.806 32.8527C117.806 32.9524 117.825 33.045 117.865 33.1305C117.905 33.2159 117.966 33.2872 118.049 33.3442C118.132 33.4011 118.213 33.451 118.292 33.4937C118.371 33.5365 118.474 33.5721 118.6 33.6006C118.726 33.6291 118.824 33.6505 118.892 33.6647C118.961 33.679 119.056 33.6968 119.179 33.7181C119.481 33.768 119.735 33.8232 119.941 33.8837C120.146 33.9443 120.362 34.0369 120.589 34.1616C120.816 34.2862 120.988 34.4572 121.103 34.6744C121.218 34.8917 121.276 35.1535 121.276 35.4598C121.276 36.0296 121.063 36.4553 120.638 36.7366C120.213 37.018 119.712 37.1587 119.135 37.1587C118.501 37.1587 117.995 37.0216 117.616 36.7473C117.238 36.4731 117.009 36.0902 116.93 35.5987Z"
                  fill="white"
                />
                <path
                  d="M125.687 36.7528V33.4404C125.687 32.5144 125.355 32.0514 124.692 32.0514C124.209 32.0514 123.822 32.2455 123.53 32.6337C123.238 33.0219 123.092 33.4654 123.092 33.964V36.7421C123.092 36.9914 122.952 37.116 122.671 37.116C122.39 37.116 122.249 36.9914 122.249 36.7421V29.8503C122.249 29.601 122.39 29.4763 122.671 29.4763C122.952 29.4763 123.092 29.601 123.092 29.8503V32.3826C123.525 31.6703 124.101 31.3141 124.822 31.3141C125.182 31.3141 125.48 31.3765 125.714 31.5011C125.948 31.6258 126.123 31.8056 126.238 32.0407C126.354 32.2758 126.431 32.5197 126.471 32.7726C126.51 33.0255 126.53 33.3265 126.53 33.6755V36.7528C126.53 36.9949 126.39 37.116 126.108 37.116C125.827 37.116 125.687 36.9949 125.687 36.7528Z"
                  fill="white"
                />
                <path
                  d="M131.125 36.7313C131.082 36.5176 131.06 36.279 131.06 36.0154C130.693 36.7776 130.059 37.1587 129.158 37.1587C128.646 37.1587 128.224 37.0109 127.893 36.7153C127.561 36.4196 127.396 36.0083 127.396 35.4811C127.396 34.199 128.379 33.5579 130.347 33.5579C130.39 33.5579 130.628 33.565 131.06 33.5792C131.06 33.1447 131.028 32.8277 130.963 32.6283C130.819 32.2009 130.394 31.9872 129.687 31.9872C129.241 31.9872 128.841 32.1225 128.487 32.3932C128.307 32.5428 128.12 32.5392 127.925 32.3825C127.731 32.2116 127.749 32.0264 127.979 31.8269C128.383 31.4921 128.996 31.3247 129.817 31.3247C130.552 31.3247 131.08 31.5099 131.401 31.8803C131.722 32.2507 131.882 32.817 131.882 33.5792V35.9299C131.882 36.2006 131.896 36.4321 131.925 36.6244C131.961 36.8595 131.86 36.9984 131.623 37.0411C131.334 37.0768 131.168 36.9735 131.125 36.7313ZM131.06 34.2203C130.714 34.199 130.455 34.1883 130.282 34.1883C129.95 34.1883 129.65 34.2185 129.379 34.2791C129.109 34.3396 128.862 34.475 128.639 34.6851C128.415 34.8953 128.304 35.1713 128.304 35.5132C128.304 35.8337 128.399 36.0777 128.59 36.2451C128.781 36.4125 129.028 36.4962 129.331 36.4962C129.842 36.4962 130.26 36.3074 130.585 35.9299C130.952 35.5096 131.111 34.9398 131.06 34.2203Z"
                  fill="white"
                />
                <path
                  d="M135.654 32.1475C135.114 32.1831 134.715 32.4022 134.459 32.8046C134.204 33.2071 134.076 33.6826 134.076 34.2311V36.7527C134.076 36.9949 133.935 37.116 133.654 37.116C133.373 37.116 133.232 36.9949 133.232 36.7527V31.7415C133.232 31.4922 133.366 31.3675 133.632 31.3675C133.899 31.3675 134.032 31.4922 134.032 31.7415V32.468C134.184 32.1404 134.405 31.8644 134.697 31.64C134.989 31.4156 135.308 31.3105 135.654 31.3248C135.928 31.339 136.061 31.485 136.054 31.7628C136.054 31.8982 136.022 31.9926 135.957 32.046C135.892 32.0994 135.791 32.1333 135.654 32.1475Z"
                  fill="white"
                />
                <path
                  d="M137.968 31.7415V36.7528C137.968 36.9949 137.827 37.116 137.546 37.116C137.265 37.116 137.125 36.9949 137.125 36.7528V31.7415C137.125 31.4922 137.265 31.3676 137.546 31.3676C137.827 31.3676 137.968 31.4922 137.968 31.7415ZM137.038 29.9999C137.038 29.6508 137.207 29.4763 137.546 29.4763C137.885 29.4763 138.054 29.6508 138.054 29.9999C138.054 30.3774 137.885 30.5662 137.546 30.5662C137.344 30.5662 137.209 30.5181 137.141 30.4219C137.072 30.3258 137.038 30.1851 137.038 29.9999Z"
                  fill="white"
                />
                <path
                  d="M142.898 36.7526V33.3228C142.898 32.9951 142.824 32.7102 142.676 32.468C142.528 32.2258 142.271 32.1047 141.903 32.1047C141.42 32.1047 141.033 32.2988 140.741 32.687C140.449 33.0752 140.303 33.5187 140.303 34.0173V36.7526C140.303 36.9948 140.163 37.1159 139.882 37.1159C139.601 37.1159 139.46 36.9948 139.46 36.7526V31.7307C139.46 31.4885 139.593 31.3674 139.86 31.3674C140.119 31.3674 140.249 31.4885 140.249 31.7307V32.5428C140.66 31.7592 141.269 31.3674 142.076 31.3674C142.343 31.3674 142.581 31.4137 142.79 31.5063C142.999 31.5989 143.161 31.7129 143.276 31.8483C143.391 31.9836 143.487 32.1421 143.563 32.3237C143.638 32.5054 143.687 32.671 143.709 32.8206C143.73 32.9702 143.741 33.1198 143.741 33.2693V36.7526C143.741 36.9948 143.601 37.1159 143.319 37.1159C143.038 37.1159 142.898 36.9948 142.898 36.7526Z"
                  fill="white"
                />
                <path
                  d="M146.973 36.3039C147.492 36.3039 147.874 36.1259 148.119 35.7697C148.364 35.4135 148.487 34.979 148.487 34.4661V33.9639C148.487 33.4511 148.366 33.0076 148.125 32.6337C147.883 32.2597 147.514 32.0727 147.017 32.0727C146.094 32.0727 145.633 32.7957 145.633 34.2418C145.633 34.4554 145.642 34.6513 145.66 34.8294C145.678 35.0075 145.716 35.1909 145.773 35.3797C145.831 35.5685 145.907 35.727 146 35.8552C146.094 35.9834 146.224 36.0902 146.39 36.1757C146.555 36.2612 146.75 36.3039 146.973 36.3039ZM148.541 32.2544V31.7415C148.541 31.4922 148.671 31.3675 148.93 31.3675C149.197 31.3675 149.33 31.4922 149.33 31.7415V36.4856C149.33 37.1552 149.154 37.7161 148.8 38.1685C148.447 38.6208 147.946 38.8897 147.298 38.9752C146.671 39.0607 146.083 39.0144 145.535 38.8363C145.399 38.7935 145.305 38.7401 145.254 38.676C145.204 38.6119 145.186 38.5122 145.2 38.3768C145.236 38.1133 145.395 38.0207 145.676 38.099C146.231 38.2628 146.743 38.3091 147.211 38.2379C148.062 38.0954 148.487 37.5078 148.487 36.4749V36.2078C148.364 36.4428 148.157 36.6405 147.865 36.8008C147.573 36.9611 147.262 37.0412 146.93 37.0412C146.159 37.0412 145.597 36.7865 145.244 36.2772C144.89 35.7679 144.714 35.0894 144.714 34.2418C144.714 33.387 144.894 32.6889 145.254 32.1475C145.615 31.6061 146.177 31.3354 146.941 31.3354C147.597 31.3354 148.13 31.6418 148.541 32.2544Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_232_5506">
                  <rect
                    width="160"
                    height="39"
                    fill="white"
                    transform="translate(0.5 0.901367)"
                  />
                </clipPath>
              </defs>
            </svg>
          </router-link>
          <img class="ofc-logo" src="@/assets/img/ofc-logo.webp" alt="" />
        </div>
      </div>
    </div>
    <div class="footer-separation"></div>
    <div class="footer-copyright-container">
      <h4>® Red point studio 2024</h4>
    </div>
  </div>
</template>

<style scoped>
.footer-container-public {
  width: 100vw;
  height: 70vh;
}

.footer-copyright-container {
  height: 5vh;
  width: 100%;
}
</style>
